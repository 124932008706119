/* Warning: please do not statically import any dependencies here except for static assets like icons */
import { NotificationIcon } from '@panwds/icons'
import { _T } from './i18n'
import { SPARKY_NAV3_BOTTOM_LEFT_EXTPOINT } from '@sparky/framework/extpoints'
import type { ExtensionsExports, MicroAppConfig, NavParams } from '@sparky/framework'

const importComponent = () => import(/* webpackPreload: true */ './NavNotifications')

export const extensions: ExtensionsExports = ({ vars, sparkyFramework: fx }) => {
  if (!fx.getFrameworkVar('use_new_left_nav') || !fx.getFrameworkVar('load_nav_extensions')) {
    return []
  }
  if (fx.getFrameworkVar('msp') && fx.hasMicroApp('mt-notifications-ui')) {
    return []
  }
  return [
    {
      point: SPARKY_NAV3_BOTTOM_LEFT_EXTPOINT,
      configs: [
        {
          id: 'sparky.fawkes.announcements',
          config: {
            key: 'announcements',
            title: _T('Announcements'),
            icon: NotificationIcon,
            mapStateToProps: () => ({ vars }),
            navComponent: () =>
              importComponent().then(({ NavNotificationsSheet }) => ({
                default: NavNotificationsSheet,
              })),
          },
        },
      ],
    },
  ]
}

export default function init({ vars, sparkyFramework: fx }: NavParams): MicroAppConfig | null {
  if (!fx.getFrameworkVar('use_new_left_nav')) {
    return null
  }
  if (fx.getFrameworkVar('msp') && fx.hasMicroApp('mt-notifications-ui')) {
    return null
  }
  return {
    actions: [
      {
        key: 'announcements',
        title: _T('Announcements'),
        navComponent: importComponent,
        mapStateToProps: () => ({ vars }),
        icon: NotificationIcon,
        avail: () => true,
        callAvail: 'override',
        _override_existing: true,
      },
    ],
  }
}
